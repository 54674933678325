import useShare from "hooks/useShare";

const ShareableLink = ({ to, children }) => {
  const handleShareClick = useShare();
  return (
    <a
      className="underline underline-offset-2 text-primary"
      href={to}
      target="_blank"
      rel="noreferrer"
      onClick={(event) => {
        event.preventDefault();
        handleShareClick(to);
      }}
    >
      {children}
    </a>
  );
};

export default ShareableLink;
