import * as amplitude from "@amplitude/analytics-browser";
import { memo, useState } from "react";
import { NavLink } from "react-router-dom";
import { ReactComponent as OwnedIcon } from "../assets/icons/bag.svg";
import { ReactComponent as CollectionsIcon } from "../assets/icons/collections.svg";
import { ReactComponent as FiltersIcon } from "../assets/icons/filters.svg";
import { ReactComponent as GoodsIcon } from "../assets/icons/goods.svg";
import FilterModal from "./modals/filter_modal/FilterModal";

const GoodsNav = memo(({
  selectedCategory,
  setSelectedCategory,
  userLinkTag,
  // setSelectedTab,
}) => {
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  return (
    <nav className="flex justify-center items-center mx-auto text-center max-w-full flex-nowrap">
      <NavLink
      to={`/${userLinkTag}`}
        className={({ isActive }) =>
          [
            "flex items-center p-4 px-3 mr-1 text-primary text-sm/none border border-grey-3 rounded-full transition-transform w-[90px]",
            "hover:border-grey-3-hover active:scale-[0.9875]",
            isActive ? "text-white font-bold bg-primary border-primary hover:border-primary" :"",
          ].join(" ")
        }
        end
        // onClick={() => setSelectedTab("goods")}
      >
        <GoodsIcon className="w-4 h-4 mr-1.5" />
        Goods
      </NavLink>
      <NavLink
      to={`/${userLinkTag}/collections`}
      className={({ isActive, isPending }) =>
        [
          "flex items-center p-4 px-3 mr-1 text-primary text-sm/none border border-grey-3 rounded-full transition-transform w-[125px]",
          "hover:border-grey-3-hover active:scale-[0.9875]",
          (isPending || isActive) && "text-white font-bold bg-primary border-primary hover:border-primary ",
        ].join(" ")
      }
        // onClick={() => setSelectedTab("collections")}
      >
        <CollectionsIcon className="w-4 h-4 mr-1.5" />
        Collections
      </NavLink>
      <NavLink
      to={`/${userLinkTag}/owned`}
      className={({ isActive, isPending }) =>
        [
          "flex items-center p-4 px-3 mr-1 text-primary text-sm/none border border-grey-3 rounded-full transition-transform w-[94px]",
          "hover:border-grey-3-hover active:scale-[0.9875]",
          (isPending || isActive) && "text-white font-bold bg-primary border-primary hover:border-primary",
        ].join(" ")
      }
        // onClick={() => setSelectedTab("owned")}
      >
        <OwnedIcon className="w-4 h-4 mr-1" />
        Owned
      </NavLink>

      <button
        className={`p-3 relative border border-grey-3 rounded-full hover:border-grey-3-hover active:scale-[0.9875]`}
        onClick={() => {
          amplitude.track("filters_tapped");
          setIsFiltersOpen(true);
        }}
      >
        <FiltersIcon className="w-6 h-6 text-grey-2" />
        {selectedCategory !== 0 && (
          <div className="bg-primary rounded-full w-4 h-4 absolute -top-[1px] -right-[1px] border-2 border-white "></div>
        )}
      </button>

      <FilterModal
        isOpen={isFiltersOpen}
        onClose={() => setIsFiltersOpen(false)}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
      />
    </nav>
  );
});

export default GoodsNav;
