import { twMerge } from "tailwind-merge";

const PrimaryButton = ({ children, className, ...props }) => (
  <button
    className={twMerge(
      "flex items-center justify-center py-4 text-lg/none text-white bg-primary rounded-full hover:filter hover:brightness-95 disabled:filter disabled:brightness-75",
      className
    )}
    {...props}
  >
    {children}
  </button>
);

export default PrimaryButton;
