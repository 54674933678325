import * as amplitude from "@amplitude/analytics-browser";
import { ReactComponent as CameraIcon } from "assets/icons/camera.svg";
import { ReactComponent as CollectionIcon } from "assets/icons/collections.svg";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";

function formatMoneyFilter(value) {
  if (value < 1000) {
    return `$${value}`;
  } else {
    // Divide by 1000 and keep one decimal place without rounding
    const formattedValue = value / 1000;
    const intPart = Math.floor(formattedValue);
    const decimalPart = Math.floor((formattedValue - intPart) * 10);

    if (decimalPart === 0) {
      return `$${intPart}K`;
    } else {
      return `$${intPart}.${decimalPart}K`;
    }
  }
}

function getBGColor(color) {
  return color && color !== "000000" ? `#${color}` : "#F7F7F7";
}

function hexToRgb(hex) {
  let r = 0,
    g = 0,
    b = 0;
  // 3 digits
  if (hex.length === 4) {
    r = parseInt(hex[1] + hex[1], 16);
    g = parseInt(hex[2] + hex[2], 16);
    b = parseInt(hex[3] + hex[3], 16);
  }
  // 6 digits
  else if (hex.length === 7) {
    r = parseInt(hex[1] + hex[2], 16);
    g = parseInt(hex[3] + hex[4], 16);
    b = parseInt(hex[5] + hex[6], 16);
  }
  return [r, g, b];
}

function luminance(r, g, b) {
  const a = [r, g, b].map(function (v) {
    v /= 255;
    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
  });
  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}

function contrast(rgb1, rgb2) {
  const lum1 = luminance(rgb1[0], rgb1[1], rgb1[2]);
  const lum2 = luminance(rgb2[0], rgb2[1], rgb2[2]);
  const brightest = Math.max(lum1, lum2);
  const darkest = Math.min(lum1, lum2);
  return (brightest + 0.05) / (darkest + 0.05);
}

function greyTextVisible(hex) {
  const rgbBackground = hexToRgb(hex);
  const rgbWhite = [24, 24, 24]; // Grey
  const ratio = contrast(rgbBackground, rgbWhite);
  return ratio >= 4.5; // For standard text. Use 3 for large text.
}

function Good({ data, onClick, children, className = null }) {
  let {
    title,
    image_url,
    image_type,
    bg_color,
    price_usd,
    owner_user_name,
    owner_link_tag,
    is_collection,
    goods_count,
    is_parsed,
  } = data;

  let bg = "";
  let bgStyles = {};
  if (!is_collection) image_type = parseInt(image_type);

  if (is_collection) {
    bg = "bg-white border border-grey-3";
  } else if (image_type === 1 || !image_url) {
    bg = "bg-[#F7F7F7]";
  } else if (image_type === 0) {
    bg = "";
    bgStyles = { backgroundColor: getBGColor(bg_color) };
  } else if (image_type === 2) {
    bg = "bg-cover bg-center";
    bgStyles = {
      backgroundImage: `url(https://goodsend.fra1.cdn.digitaloceanspaces.com/main-space/images/${image_url})`,
    };
  }

  const onGoodClick = (event) => {
    onClick(event);
    amplitude.track("good_clicked", { id: data.id });
  };

  return (
    <div
      style={bgStyles ? bgStyles : null}
      className={twMerge(
        `realtive flex flex-col relative rounded-3xl aspect-[9_/_13] object-contain cursor-pointer`,

        is_collection
          ? 'hover:bg-grey-3 hover:border-grey-3 hover:before:rotate-[0deg] hover:before:h-[100%] before:transition-transform before:content-[""] before:bg-grey-1 before:rounded-3xl before:h-[101%] before:w-[91%] before:absolute before:top-[-1px] before:right-[5px] before:z-[-1] before:rotate-[5deg]'
          : "hover:filter hover:brightness-95 ",
        owner_user_name && "mt-4",
        bg,
        className
      )}
      onClick={onGoodClick}
    >
      {owner_user_name && (
        <Link
          className="absolute z-10 -top-4 left-2 max-w-[85%] block overflow-hidden text-ellipsis bg-black-opacity backdrop-blur-md rounded-full p-2 text-sm/none text-white whitespace-pre"
          to={`/${owner_link_tag}`}
          onClick={(e) => e.stopPropagation()}
        >
          by <span className="font-bold">{owner_user_name}</span>
        </Link>
      )}
      {is_collection ? (
        <div className="grid grid-cols-5 grid-rows-2 w-full h-full gap-2 flex-1 p-1">
          <div
            className={`relative flex-1 flex p-2 items-center justify-center  row-span-2 col-span-3 bg-cover bg-center bg-no-repeat rounded-tl-3xl rounded-bl-lg bg-grey-4`}
            style={{
              backgroundImage:
                image_type.split(",")[0] == 2
                  ? `url(https://goodsend.fra1.cdn.digitaloceanspaces.com/main-space/images/${
                      image_url.split(",")[0]
                    })`
                  : null,
              backgroundColor: getBGColor(bg_color?.split(",")[0]),
            }}
          >
            {image_type.split(",")[0] != 2 && (
              <img
                src={`https://goodsend.fra1.cdn.digitaloceanspaces.com/main-space/images/${
                  image_url.split(",")[0]
                }`}
                alt={title}
                className="absolute w-full h-full object-contain max-h-[80%] max-w-[80%]"
              />
            )}
          </div>
          <div
            className={`relative flex-1 flex p-2 items-center justify-center col-span-2 bg-cover bg-center bg-no-repeat rounded-tr-3xl  bg-grey-4`}
            style={{
              backgroundImage:
                image_type.split(",")[2] == 2
                  ? `url(https://goodsend.fra1.cdn.digitaloceanspaces.com/main-space/images/${
                      image_url.split(",")[2]
                    })`
                  : null,
              backgroundColor: getBGColor(bg_color?.split(",")[2]),
            }}
          >
            {image_url.split(",")[2] && image_type.split(",")[2] != 2 && (
              <img
                src={`https://goodsend.fra1.cdn.digitaloceanspaces.com/main-space/images/${
                  image_url.split(",")[2]
                }`}
                alt={title}
                className="absolute w-full h-full object-contain max-h-[80%] max-w-[80%]"
              />
            )}
            <div className="absolute top-0 left-0 rounded-tr-3xl w-full h-full flex items-center justify-center bg-black bg-opacity-55 leading-none text-white">
              <CollectionIcon className="w-4 h-4 mr-1" />
              {goods_count}
            </div>
          </div>

          <div
            className={`relative flex-1 flex p-2 items-center justify-center  col-span-2 bg-cover bg-center bg-no-repeat rounded-br-lg bg-grey-4`}
            style={{
              backgroundImage:
                image_type.split(",")[1] == 2
                  ? `url(https://goodsend.fra1.cdn.digitaloceanspaces.com/main-space/images/${
                      image_url.split(",")[1]
                    })`
                  : null,
              backgroundColor: getBGColor(bg_color?.split(",")[1]),
            }}
          >
            {image_type.split(",")[1] != 2 && (
              <img
                src={`https://goodsend.fra1.cdn.digitaloceanspaces.com/main-space/images/${
                  image_url.split(",")[1]
                }`}
                alt={title}
                className="absolute w-full h-full object-contain max-h-[80%] max-w-[80%]"
              />
            )}
          </div>
        </div>
      ) : (
        <div
          className={`relative flex justify-center flex-wrap content-center items-center flex-1 my-6 mx-4`}
        >
          {image_url && image_type !== 2 && !is_collection && (
            <img
              className="h-full absolute w-full object-contain max-w-[175px]"
              src={`https://goodsend.fra1.cdn.digitaloceanspaces.com/main-space/images/${image_url}`}
              alt={title}
            />
          )}

          {!image_url && (
            <div className="animate-pulse text-sm flex items-center">
              {is_parsed ? (
                <div className="flex flex-col items-center text-center">
                  <CameraIcon className="mb-1" /> Upload
                  <br />
                  Image Manually
                </div>
              ) : (
                "Creating preview..."
              )}
            </div>
          )}
        </div>
      )}
      {title && (
        <div
          style={
            image_type == 2
              ? {
                  backgroundImage:
                    "linear-gradient(0deg, rgba(4, 3, 3, 0.5) 0%, rgba(7, 5, 5, 0.25) 50%, rgba(16, 12, 13, 0) 100%)",
                  color: "white",
                  height: "80px",
                }
              : image_type == 0 && !greyTextVisible(getBGColor(bg_color))
              ? { color: "white" }
              : { color: "#181818" }
          }
          className="text-base font-medium h-[61px] flex justify-end flex-col rounded-b-3xl px-4 pb-4"
        >
          <p
            style={{
              display: "-webkit-box",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
            }}
            className="overflow-hidden text-ellipsis display-[-webkit-box]"
          >
            {title}
          </p>

          {price_usd && price_usd > 0 && (
            <p className="text-white text-sm/none tracking-tight absolute top-2 right-2 bg-black-opacity backdrop-blur-md p-2 rounded-3xl">
              {formatMoneyFilter(price_usd)}
            </p>
          )}
        </div>
      )}
      {children}
    </div>
  );
}

export default Good;
