import {
  Identify,
  identify,
  init,
  setUserId,
  add
} from "@amplitude/analytics-browser";
import { createContext, useContext, useEffect } from "react";
import { useUser } from "./UserContext";
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';

const AmplitudeContext = createContext();

export const AmplitudeProvider = ({ children }) => {
  const { user, isWebView, appVersion } = useUser();
  useEffect(() => {
    init("2b182c9c0065bfb875656d08dcf534a2", { minIdLength: 1 });
    const sessionReplayTracking = sessionReplayPlugin({
      sampleRate: 0.5,
      });
    add(sessionReplayTracking);
    if (user) {
      setUserId(user.id);
      const identifyEvent = new Identify();
      identifyEvent.setOnce("name", user.name);
      identifyEvent.setOnce("in_app", isWebView);
      identifyEvent.setOnce("app_version", appVersion);
      identify(identifyEvent);
    }
  }, [user, isWebView, appVersion]);

  return (
    <AmplitudeContext.Provider value={{}}>{children}</AmplitudeContext.Provider>
  );
};

export const useAmplitude = () => useContext(AmplitudeContext);
