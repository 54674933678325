import * as amplitude from "@amplitude/analytics-browser";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useToast } from "ToastContext";
import { useUser } from "UserContext";
import { ReactComponent as CheckIcon } from "assets/icons/check.svg";
import { ReactComponent as CrossIcon } from "assets/icons/close.svg";
import { ReactComponent as LeftArrow } from "assets/icons/left_arrow.svg";
import { ReactComponent as LinkIcon } from "assets/icons/link.svg";
import { ReactComponent as SpinnerIcon } from "assets/icons/spinner.svg";
import { ReactComponent as UsersIcon } from "assets/icons/users.svg";
import PrimaryButton from "components/PrimaryButton";
import BottomModal from "components/modals/bottom_modal/BottomModal";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { getUserInfo, updateUserInfo } from "services/api";

function PersonalInfoModal({ isOpen, onClose }) {
  const { addToast } = useToast();
  const { user } = useUser();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  useEffect(() => {
    if (isOpen) amplitude.track(`personalinfo_opened`);
  }, [isOpen]);

  // const [selectedCategories, setSelectedCategories] = useState([]);
  const [imagePreview, setImagePreview] = useState(null); // State to hold the image preview URL

  const { mutate, isPending, isSuccess } = useMutation({
    mutationFn: ({ formData }) => updateUserInfo(formData),
    onSettled: (data, more, vars) => {
      queryClient.invalidateQueries({ queryKey: ["currentUserInfo"] });
      queryClient.invalidateQueries({ queryKey: ["userInfo", user.link_tag] });
      addToast("Changes Saved");
      navigate("/" + vars.formData.get("link_tag"));
      onClose();
    },
  });

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("link_tag", data.link_tag);

    if (data.image) {
      if (data.image instanceof File) {
        formData.append("image", data.image);
      } else formData.append("image", imagePreview);
    }
    mutate({ formData });
  };

  const onFileChange = (file) => {
    if (file) {
      setImagePreview(URL.createObjectURL(file)); // Create and set the image preview URL
    }
    setValue("image", file);
  };

  useEffect(() => {
    // Clean up the image preview URL to avoid memory leaks
    return () => {
      if (imagePreview) {
        URL.revokeObjectURL(imagePreview);
      }
    };
  }, [imagePreview]);

  useEffect(() => {
    if (user) {
      setValue("name", user.name);
      setValue("link_tag", user.link_tag);
      if (user.photo_url)
        if (user.photo_url.includes("t.me")) {
          const image = new Image();
          image.src = user.photo_url;
          image.onload = () => {
            if (image.height < 10) setImagePreview("");
            else setImagePreview(user.photo_url);
          };
          image.onerror = () => setImagePreview("");
        } else
          setImagePreview(
            `https://goodsend.fra1.cdn.digitaloceanspaces.com/user-avatars/images/${user.photo_url}`
          );
    }
  }, [user, setValue]);

  // Drag and drop handlers
  const handleDragOver = useCallback((event) => {
    event.preventDefault(); // Prevent default behavior to enable drop
  }, []);

  const handleDrop = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    const files = event.dataTransfer.files;
    if (files && files[0]) {
      onFileChange(files[0]); // Handle the first file
    }
  }, []);

  const linkTag = watch("link_tag");
  const [debouncedTerm, setDebouncedTerm] = useState(linkTag);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedTerm(linkTag);
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [linkTag]);

  const {
    data: linkTagResults,
    isLoading: isLinkTagPending,
    isError,
  } = useQuery({
    queryKey: ["userInfo", debouncedTerm],
    queryFn: () => getUserInfo(debouncedTerm),
    enabled:
      !!debouncedTerm &&
      debouncedTerm !== user.link_tag &&
      debouncedTerm.length > 2,
  });

  return (
    <BottomModal
      isOpen={isOpen}
      onClose={onClose}
      zIndex={30}
      containerClassName={"max-w-[550px]"}
      isFullHeight={true}
      className={"max-h-[100vh] h-full"}
      isNested={true}
    >
      <div className="p-3 pb-32 pt-4">
        <div className="flex justify-between align-center mb-8 p-2">
          <LeftArrow
            onClick={() => onClose()}
            className="absolute text-black flex-grow-0"
          />
          <h3 className="text-lg font-bold flex-grow text-center">
            Personal Information
          </h3>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="relative mb-4">
            <input
              id="name"
              type="text"
              className="bg-grey-4 text-lg p-4 rounded-full w-full ps-12 focus:outline focus:outline-1 outline-black"
              placeholder="Name"
              {...register("name", { required: true, minLength: 3 })}
            />

            <UsersIcon className="absolute top-[18px] left-5 text-grey-2" />

            {errors.name && (
              <p className="text-grey-2">
                Name is required and must be longer than 3 symbols.
              </p>
            )}
          </div>

          <div className="relative mb-6">
            <input
              id="link_tag"
              type="text"
              className="bg-grey-4 text-lg p-4 rounded-full w-full ps-36 focus:outline focus:outline-1 outline-black"
              {...register("link_tag", {
                required: true,
                minLength: 3,
                maxLength: 36,
                pattern: /^[a-zA-Z0-9_]+$/,
              })}
            />
            <LinkIcon className="absolute top-[16px] left-4 text-grey-2 w-6 h-6" />
            <p className="absolute text-lg/none top-[19px] left-11 text-grey-2">
              goodsent.it/
            </p>
            {isLinkTagPending ? (
              <SpinnerIcon className="absolute top-[16px] right-4 text-primary w-6 h-6 animate-spin" />
            ) : (linkTagResults || errors.link_tag) &&
              linkTagResults?.link_tag !== user.link_tag ? (
              <CrossIcon className="absolute top-[16px] right-4 text-red-400 w-6 h-6" />
            ) : (
              <CheckIcon className="absolute top-[16px] right-4 text-primary w-6 h-6" />
            )}

            {errors.link_tag && (
              <p className="text-grey-2">
                Tag is required, must be between 3 and 36 characters long and
                can contain only letters, numbers and underscores.
              </p>
            )}
          </div>

          <div className="mb-8">
            <p className="mb-4">Photo</p>
            <div
              className={`flex items-center mb-4 `}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              <div
                style={{ backgroundImage: `url(${imagePreview})` }}
                className="bg-grey-4 w-[72px] h-[72px] rounded-full text-primary text-[32px] font-black flex items-center justify-center mr-4 bg-cover bg-center bg-no-repeat"
              >
                {!imagePreview && user.name[0]}
              </div>
              {imagePreview ? (
                <>
                  <label
                    htmlFor="image"
                    className="bg-primary p-2 rounded-full text-white mr-6 leading-none cursor-pointer"
                  >
                    Change Photo
                  </label>
                  <button
                    className="text-primary font-bold underline leading-none"
                    type="button"
                    onClick={() => setImagePreview(null)}
                  >
                    Remove Photo
                  </button>
                </>
              ) : (
                <>
                  <label
                    htmlFor="image"
                    className="bg-primary p-2 rounded-full text-white leading-none cursor-pointer"
                  >
                    Upload Photo
                  </label>
                </>
              )}
              <input
                id="image"
                type="file"
                {...register("image")}
                onChange={(e) => onFileChange(e.target.files[0])}
                className="hidden"
              />
            </div>
          </div>
          <PrimaryButton
            className="w-[95vw] max-w-[500px] fixed bottom-10 left-1/2 -translate-x-1/2 disabled:opacity-50 "
            type="submit"
            disabled={
              errors.name ||
              errors.link_tag ||
              isPending ||
              (linkTagResults && linkTagResults.link_tag !== user.link_tag)
            }
          >
            {isPending ? "Saving..." : "Save Changes"}
          </PrimaryButton>
        </form>
      </div>
    </BottomModal>
  );
}

export default PersonalInfoModal;
