import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { PaywallProvider } from "PaywallContext";
import { ToastProvider } from "ToastContext";
import NavButtons from "components/NavButtons";
import ScrollToTop from "components/ScrollToTop";
import Admin from "pages/Admin";
import Care from "pages/Care";
import Explore from "pages/Explore";
import GoodEdit from "pages/GoodEdit";
import Goods from "pages/Goods";
import Search from "pages/Search";
import Test from "pages/Test";
import OnboardingConnectTelegram from "pages/intro/ConnectTelegram";
import OnboardingStepFive from "pages/intro/LastStep";
import LastStepName from "pages/intro/LastStepName";
import LastStepPhoto from "pages/intro/LastStepPhoto";
import LastStepTag from "pages/intro/LastStepTag";
import OnboardingOneTapSave from "pages/intro/OneTapSave";
import {
  SaveViaShareFirst,
  SaveViaShareSecond,
} from "pages/intro/SaveViaShare";
import OnboardingStepFour from "pages/intro/StepFour";
import OnboardingStepOne from "pages/intro/StepOne";
import OnboardingStepThree from "pages/intro/StepThree";
import OnboardingStepTwo from "pages/intro/StepTwo";
import { useEffect } from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
  useParams,
} from "react-router-dom";
import { getAppVersion } from "services/api";
import { AmplitudeProvider } from "./AmplitudeContext";
import { GoodModalProvider } from "./GoodModalContext";
import { UserProvider, useUser } from "./UserContext";

if (window.location.hostname === "goodsend.it") {
  Sentry.init({
    dsn: "https://75300a83c6adfd7923b784a24a2f891f@o4507198381752320.ingest.de.sentry.io/4507198450958416",
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.1, // Adjust this value in production
  });
}

const RedirectIfNotLoggedIn = () => {
  const { user, isLoading } = useUser();
  if (isLoading) {
    return null;
  }
  // console.log("link_tag", user?.link_tag);
  if (!user?.link_tag) {
    console.log(`redirecting to intro isLoading=${isLoading}`);
    return <Navigate to="/intro" replace />;
  } else {
    return <Navigate to={`/${user.link_tag}`} replace />;
  }
};

const AdminWrapper = () => {
  const { user } = useUser();
  if ([5, 10, 12].includes(user?.id)) {
    return <Admin />;
  }
  return <Navigate to="/" replace />;
};

const App = () => {
  const setAppVersion = (version) => {
    alert("Received app version from native app: " + version);
  };
  useEffect(() => {
    window.setAppVersion = setAppVersion;

    return () => {
      delete window.setAppVersion;
    };
  }, []);

  useEffect(() => {
    function checkForUpdates() {
      // console.log("Checking for updates...");
      getAppVersion()
        .then((data) => {
          // console.log("Got app version app: " + data.version);
          const currentVersion = localStorage.getItem("appVersion");

          if (currentVersion !== data.version) {
            localStorage.setItem("appVersion", data.version);
            if (currentVersion) window.location.reload();
          }
        })
        .catch((e) => {
          console.error("Error checking for updates", e);
        });
    }
    checkForUpdates();

    window.addEventListener("focus", checkForUpdates);

    return () => {
      window.removeEventListener("focus", checkForUpdates);
    };
  }, []);

  const GoodsWrapper = ({ tab }) => {
    const { userLinkTag } = useParams();
    return <Goods selectedTab={tab} userLinkTag={userLinkTag} />;
  };

  const GoodEditWrapper = () => {
    const { goodId } = useParams();
    return <GoodEdit goodId={goodId} />;
  };

  const LastStepWrapper = () => {
    const onboarding = localStorage.getItem("onboarding");
    if (onboarding) {
      return <Navigate to="/last-step/final" />;
    }
    localStorage.setItem("onboarding", "true");
    return <Navigate to="/last-step/name" />;
  };

  return (
    <>
      <Router>
        <ToastProvider>
          <UserProvider>
            <AmplitudeProvider>
              <PaywallProvider>
                <GoodModalProvider>
                  <ScrollToTop />
                  <Routes>
                    <Route path="/" element={<RedirectIfNotLoggedIn />} />
                    <Route
                      path="/:userLinkTag"
                      element={<GoodsWrapper tab="goods" />}
                    />
                    <Route
                      path="/:userLinkTag/collections"
                      element={<GoodsWrapper tab="collections" />}
                    />
                    <Route
                      path="/:userLinkTag/owned"
                      element={<GoodsWrapper tab="owned" />}
                    />
                    <Route path="/search" element={<Search />} />
                    <Route path="/test" element={<Test />} />
                    <Route path="/intro/1" element={<OnboardingStepOne />} />
                    <Route path="/intro/2" element={<OnboardingStepTwo />} />
                    <Route path="/intro/3" element={<OnboardingStepThree />} />
                    <Route path="/intro/4" element={<OnboardingStepFour />} />
                    <Route path="/last-step" element={<LastStepWrapper />} />
                    <Route path="/last-step/name" element={<LastStepName />} />
                    <Route
                      path="/last-step/photo"
                      element={<LastStepPhoto />}
                    />
                    <Route path="/last-step/tag" element={<LastStepTag />} />
                    <Route
                      path="/last-step/final"
                      element={<OnboardingStepFive />}
                    />
                    <Route
                      path="/intro/one-tap-save"
                      element={<OnboardingOneTapSave />}
                    />
                    <Route
                      path="/intro/save-via-share"
                      element={<SaveViaShareFirst />}
                    />
                    <Route
                      path="/intro/save-via-share/second"
                      element={<SaveViaShareSecond />}
                    />
                    <Route
                      path="/intro/connect-telegram"
                      element={<OnboardingConnectTelegram />}
                    />
                    <Route path="/care" element={<Care />} />
                    <Route
                      path="/explore/picks"
                      element={<Explore type={"picks"} />}
                    />
                    <Route
                      path="/explore/community"
                      element={<Explore type={"community"} />}
                    />
                    <Route
                      path="/explore/circle"
                      element={<Explore type={"circle"} />}
                    />
                    <Route
                      path="/explore"
                      element={<Navigate to="/explore/community" replace />}
                    />
                    <Route
                      path="/intro"
                      element={<Navigate to="/intro/1" replace />}
                    />
                    <Route path="/good/:goodId" element={<GoodEditWrapper />} />
                    <Route path="/admin" element={<AdminWrapper />} />
                  </Routes>
                  <NavButtons />
                </GoodModalProvider>
              </PaywallProvider>
            </AmplitudeProvider>
          </UserProvider>
        </ToastProvider>
      </Router>
    </>
  );
};

export default App;
