import { useCallback } from "react";
import { useToast } from "ToastContext";
const useShare = () => {
  const { addToast } = useToast();
  const handleShareClick = useCallback((url = null) => {
    console.log(url ? url : window.location.href);
    if (navigator.share) {
      // Web Share API is supported
      navigator
        .share({
          title: document.title,
          url: url ? url : window.location.href,
        })
        .then(() => addToast("Thanks for sharing!"))
        .catch((error) => console.error("Error sharing content:", error));
    } else {
      // Fallback to copying the URL to the clipboard
      navigator.clipboard
        .writeText(url ? url : window.location.href)
        .then(() => addToast("Link copied to clipboard"))
        .catch((error) => console.error("Error copying content:", error));
    }
  }, []); // Empty dependency array means this callback never changes

  return handleShareClick;
};

export default useShare;
