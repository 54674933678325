export const categories = [
  { id: "0", name: "Clothing" },
  { id: "1", name: "Shoes" },
  { id: "2", name: "Electronics" },
  { id: "3", name: "Jewellery" },
  { id: "4", name: "Bags" },
  { id: "5", name: "Watches" },
  { id: "6", name: "Accessories" },
  { id: "7", name: "Home" },
  { id: "8", name: "Art&Craft" },
  { id: "9", name: "Other" },
];
