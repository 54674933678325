import * as amplitude from "@amplitude/analytics-browser";
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { useGoodModal } from "GoodModalContext";
import { usePaywall } from "PaywallContext";
import { useUser } from "UserContext";
import { ReactComponent as BagIcon } from "assets/icons/bag.svg";
import { ReactComponent as CameraIcon } from "assets/icons/camera.svg";
import { ReactComponent as CollectionIcon } from "assets/icons/collections.svg";
import { ReactComponent as GridIcon } from "assets/icons/goods.svg";
import { ReactComponent as HeartIcon } from "assets/icons/heart.svg";
import { ReactComponent as InstaIcon } from "assets/icons/insta.svg";
import { ReactComponent as LinkIcon } from "assets/icons/link.svg";
import { ReactComponent as NoteIcon } from "assets/icons/note.svg";
import { ReactComponent as CheckIcon } from "assets/icons/rounded_check.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import { ReactComponent as WarningIcon } from "assets/icons/warning.svg";
// import GoodsGrid from "components/GoodsGrid";
import PrimaryButton from "components/PrimaryButton";
// import { GoodMoreModal } from "components/modals/good_more_modal/GoodMoreModal";
import { AnimatePresence, motion } from "framer-motion";
import { useArchiveMutation } from "hooks/useArchiveMutation";
import { useCollectMutation } from "hooks/useCollectMutation";
import { useOwnMutation } from "hooks/useOwnMutation";
import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";
import { addNoteToGood, fetchSimilarGoods, markAsUnowned } from "services/api";
import { getGoodDetails } from "../../../services/api";
import BottomModal from "../bottom_modal/BottomModal";
// import AddToCollectionModal from "./AddToCollectionModal";
import CollectBtn from "./CollectBtn";
// import { CollectedByModal } from "./CollectedByModal";
import { lazy, Suspense } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { GoodModalSkeleton } from "./GoodModalSkeleton";
import ShareBtn from "./ShareBtn";

const CollectedByModal = lazy(() => import("./CollectedByModal"));
const AddToCollectionModal = lazy(() => import("./AddToCollectionModal"));
const GoodsGrid = lazy(() => import("components/GoodsGrid"));
const GoodMoreModal = lazy(() =>
  import("components/modals/good_more_modal/GoodMoreModal")
);

function GoodModal({
  show,
  onClose,
  selectedCategory,
  goodId,
  isNested = false,
  mainButton = null,
  bottom_addons = null,
  ownerUserId = null,
}) {
  const { openPaywall } = usePaywall();
  const { user: currentUser } = useUser();
  const userId = currentUser?.id;
  const queryClient = useQueryClient();

  const { openGoodModal } = useGoodModal();

  const [collectedByOpen, setCollectedByOpen] = useState(false);
  const toggleCollectedBy = () => setCollectedByOpen((prev) => !prev);

  const [goodMoreOpen, setGoodMoreOpen] = useState(false);

  const [addToCollectionOpen, setAddToCollectionOpen] = useState(false);
  const [isNoteEditOpen, setIsNoteEditOpen] = useState(false);
  const [goodNote, setGoodNote] = useState("");

  const [isOwnedPopupOpen, setOwnedPopupOpen] = useState(false);

  const onArchiveClick = useArchiveMutation(userId, selectedCategory);
  const { mutate: onCollectClick, isPending: isCollectPending } =
    useCollectMutation(userId);

  const {
    data: goodDetails,
    isLoading,
    isError,
    isFetching,
    isPending,
  } = useQuery({
    queryKey: ["goodDetails", goodId],
    queryFn: () => getGoodDetails(goodId, ownerUserId),
    enabled: goodId != null && show,
  });

  const {
    data: similarGoods,
    isPending: isPendingSimilar,
    isFetching: isFetchingSimilar,
    isLoading: isLoadingSimilar,
    hasNextPage: hasNextPageSimilar,
    isFetchingNextPage: isFetchingNextPageSimilar,
    fetchNextPage,
  } = useInfiniteQuery({
    queryKey: ["similarGoods", goodId],
    queryFn: ({ pageParam = 0 }) => fetchSimilarGoods(goodId, pageParam),
    getNextPageParam: (lastPage, pages) => lastPage?.offset || null,
    enabled: goodId != null && show,
  });

  useEffect(() => {
    setGoodNote(goodDetails?.note);
  }, [goodDetails]);

  const handleArchiveClick = () => {
    onArchiveClick.mutate(goodId);
    onPopupClose();
    onArchiveClick.reset();
    amplitude.track("good_archive", { id: goodId });
  };

  const handleCollectClick = () => {
    onCollectClick(goodId);
    amplitude.track("good_collect", { id: goodId });
  };

  const navigate = useNavigate();
  const location = useLocation();

  const onPopupClose = () => {
    onClose();
    setCollectedByOpen(false);
    setGoodMoreOpen(false);
    const params = new URLSearchParams(location.search);
    params.delete("good_id");
    navigate(`${location.pathname}?${params.toString()}`, {
      replace: true,
    });
  };

  let image_url = goodDetails?.image_url
    ? `https://goodsend.fra1.cdn.digitaloceanspaces.com/main-space/images/${goodDetails.image_url}`
    : "";
  let collected_by_names = goodDetails?.collected_by_names
    ? goodDetails.collected_by_names.split(", ")
    : [];
  let collected_by_link_tags = goodDetails?.collected_by_link_tags
    ? goodDetails.collected_by_link_tags.split(", ")
    : [];
  let collected_by_user_ids = goodDetails?.collected_by_user_ids
    ? goodDetails.collected_by_user_ids.split(", ").map(Number)
    : [];

  let owned_by_user_ids = goodDetails?.owned_by_user_ids
    ? goodDetails.owned_by_user_ids.split(", ").map(Number)
    : [];

  const { mutate: addNoteMutation, isPending: isPendingAddNote } = useMutation({
    mutationFn: (data) => addNoteToGood(goodId, data),
    onSuccess: () => {
      setIsNoteEditOpen(false);
    },
    onSettled: () => {
      queryClient.invalidateQueries(["goodDetails", goodId]);
    },
  });

  const saveNote = () => {
    if (!currentUser?.is_premium) openPaywall("notes", "notes");
    else {
      addNoteMutation({ note: goodNote });
    }
  };

  const { mutate: markAsOwnedMutation } = useOwnMutation(userId);

  const { mutate: markAsUnownedMutation } = useMutation({
    mutationFn: (goodId) => markAsUnowned(goodId),
    onSettled: () => {
      queryClient.invalidateQueries(["goodDetails", goodId]);
    },
  });

  const onBagClick = () => {
    if (!currentUser?.is_premium) openPaywall("owned", "owned_button");
    else {
      setOwnedPopupOpen(true);
    }
  };

  const { ref, inView: inViewSimilar } = useInView({
    threshold: 0,
    rootMargin: "0px 0px 500px 0px",
  });

  if (
    inViewSimilar &&
    hasNextPageSimilar &&
    !isPendingSimilar &&
    !isFetchingSimilar
  ) {
    fetchNextPage();
  }

  return (
    <>
      <BottomModal
        className={"pt-0"}
        isOpen={show}
        onClose={onPopupClose}
        zIndex={20}
        isNested={isNested}
        containerClassName={" max-w-[550px] h-full"}
      >
        <div className="flex flex-col justify-between w-full">
          {isError && <div>Error loading good details</div>}
          {!goodDetails && !isPending && !isError && (
            <div>Good details not found</div>
          )}
          <AnimatePresence mode="wait">
            {isPending || isLoading || !goodDetails ? (
              <GoodModalSkeleton key="modal-skeleton" />
            ) : (
              <motion.div
                key="modal"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.2 }}
              >
                <a
                  style={{
                    backgroundImage: `url(${image_url})`,
                    backgroundColor:
                      "#" +
                      (goodDetails?.bg_color &&
                      goodDetails?.bg_color !== "000000"
                        ? goodDetails?.bg_color
                        : "f7f7f7"),
                  }}
                  className={`relative block w-full h-[50vh] bg-no-repeat rounded-t-3xl mb-4 ${
                    !image_url && "flex items-center justify-center bg-grey-4"
                  }
                  ${
                    goodDetails?.image_type === 2
                      ? "bg-cover bg-top"
                      : "bg-contain bg-center"
                  }`}
                  href={goodDetails.url}
                  target="_blank"
                  rel="noreferrer"
                >
                  {!image_url && (
                    <div className="animate-pulse text-sm flex items-center m-auto">
                      {goodDetails?.is_parsed ? (
                        <div className="flex flex-col items-center text-center">
                          <CameraIcon className="mb-1" /> Upload
                          <br />
                          Image Manually
                        </div>
                      ) : (
                        "Creating preview..."
                      )}
                    </div>
                  )}
                  <div className="flex absolute bottom-4 left-4">
                    {goodDetails.price_usd > 0 && (
                      <div className="flex items-center text-white text-sm/none bg-black-opacity backdrop-blur-md p-2 rounded-3xl mr-2">
                        ${goodDetails.price_usd}
                      </div>
                    )}
                    {owned_by_user_ids.includes(userId) && (
                      <div className="flex items-center text-white text-sm/none bg-primary backdrop-blur-md p-2 rounded-3xl">
                        <CheckIcon className="w-4 h-4 inline-block mr-1" />
                        Owned
                      </div>
                    )}
                  </div>
                </a>
                <div className="px-4 pb-6 text-black">
                  <div className="relative flex items-center mb-3">
                    {goodDetails.insta_tag ? (
                      <InstaIcon className="inline-block w-4 h-4 mr-1" />
                    ) : (
                      <LinkIcon className="inline-block w-4 h-4 mr-1" />
                    )}
                    <a
                      href={goodDetails.url}
                      target="_blank"
                      rel="noreferrer"
                      className="text-sm/none"
                    >
                      {goodDetails.insta_tag
                        ? goodDetails.insta_tag
                        : goodDetails.url.split("/")[2]}
                    </a>
                  </div>
                  {!goodDetails?.title && goodDetails?.is_parsed ? (
                    <>
                      <p className="flex items-center text-lg mb-4">
                        <WarningIcon className="inline mr-1" /> Upload Image
                        Manually
                      </p>
                      <p className="text-lg text-grey-2 max-w-[370px] mb-10">
                        We couldn’t get an image because of the website privacy
                        settings. Please update image & details manually.
                      </p>
                    </>
                  ) : (
                    <>
                      <p className="text-lg font-bold mb-4">
                        {goodDetails.title}
                      </p>

                      {ownerUserId &&
                        ((currentUser?.id === ownerUserId &&
                          collected_by_user_ids.includes(currentUser?.id)) ||
                          goodDetails.note) && (
                          <>
                            <div className="relative mb-4">
                              <div
                                className={`bg-grey-4 text-lg p-4 rounded-2xl w-full ps-12 focus:outline focus:outline-1 outline-black ${
                                  !goodDetails.note && "text-grey-2"
                                }`}
                                onClick={() =>
                                  currentUser?.id === ownerUserId
                                    ? setIsNoteEditOpen(true)
                                    : null
                                }
                              >
                                {goodDetails.note
                                  ? goodDetails.note
                                  : "Type here to add a note"}
                              </div>
                              <NoteIcon className="absolute top-[18px] left-4 text-grey-2" />
                            </div>
                            <BottomModal
                              isOpen={isNoteEditOpen}
                              onClose={() => setIsNoteEditOpen(false)}
                              zIndex={21}
                            >
                              <div className="px-4 mt-8">
                                <div className="max-w-[350px]">
                                  <h2 className="text-xl font-bold mb-1">
                                    Add note to "{goodDetails.title}"
                                  </h2>
                                  <p className="text-grey-2 mb-4 ">
                                    Add some note for yourself or friend, like
                                    size, color or other thing etc
                                  </p>
                                </div>
                                <div className="relative mb-4">
                                  <textarea
                                    id="name"
                                    type="text"
                                    className="bg-grey-4 text-lg p-4 rounded-2xl w-full ps-12 focus:outline focus:outline-1 outline-black"
                                    placeholder="Type here to add a note"
                                    value={goodNote}
                                    onChange={(e) =>
                                      setGoodNote(e.target.value)
                                    }
                                    rows="4"
                                    readOnly={currentUser?.id !== ownerUserId}
                                  />
                                  <NoteIcon className="absolute top-[18px] left-4 text-grey-2" />
                                </div>
                                <PrimaryButton
                                  onClick={saveNote}
                                  className="w-full bg-primary rounded-full text-white text-lg mb-10"
                                  disabled={isPendingAddNote}
                                >
                                  {isPendingAddNote ? "Saving.." : "Save Note"}
                                </PrimaryButton>
                              </div>
                            </BottomModal>
                          </>
                        )}
                    </>
                  )}
                  <div className="flex justify-between mb-6">
                    {mainButton ? (
                      <>
                        {mainButton(goodId)}
                        <ShareBtn
                          isSmall={true}
                          goodId={goodId}
                          className="mr-2"
                        />
                        <button
                          onClick={() => setGoodMoreOpen(true)}
                          className="rounded-full bg-grey-4 w-14 h-14 flex justify-center items-center hover:filter hover:brightness-95"
                        >
                          <div className="flex space-x-1">
                            <span className="w-1.5 h-1.5 bg-grey-2 rounded-full"></span>
                            <span className="w-1.5 h-1.5 bg-grey-2 rounded-full"></span>
                            <span className="w-1.5 h-1.5 bg-grey-2 rounded-full"></span>
                          </div>
                        </button>
                      </>
                    ) : !image_url &&
                      goodDetails?.is_parsed &&
                      (goodDetails?.owner_user_id === userId ||
                        [5, 10, 12].includes(currentUser?.id)) ? (
                      <>
                        <PrimaryButton className="flex-grow mr-3 bg-primary rounded-full flex items-center justify-center text-white text-lg">
                          <Link to={`/good/${goodId}`}>
                            Update good manually
                          </Link>
                        </PrimaryButton>

                        <button
                          onClick={handleArchiveClick}
                          className="rounded-full bg-grey-4 w-14 h-14 flex justify-center items-center hover:filter hover:brightness-95"
                        >
                          <TrashIcon className="w-6 h-6" />
                        </button>
                      </>
                    ) : collected_by_user_ids.includes(userId) ? (
                      <>
                        <PrimaryButton
                          onClick={() => setAddToCollectionOpen(true)}
                          className="flex-grow mr-2 bg-primary rounded-full flex items-center justify-center text-white text-lg px-2"
                        >
                          <CollectionIcon className="w-4 h-4 mr-2 flex-shrink-0 max-[400px]:hidden" />
                          <p className="max-[400px]:text-base">
                            Add to collection
                          </p>
                        </PrimaryButton>
                        <button
                          onClick={onBagClick}
                          className="rounded-full bg-grey-4 mr-2 w-14 h-14 flex justify-center items-center hover:filter hover:brightness-95"
                        >
                          <BagIcon
                            className={`${
                              owned_by_user_ids.includes(userId)
                                ? "text-primary"
                                : "text-grey-2"
                            }`}
                          />
                        </button>
                        <ShareBtn
                          isSmall={true}
                          goodId={goodId}
                          className="mr-2"
                        />

                        <button
                          onClick={() => setGoodMoreOpen(true)}
                          className="rounded-full bg-grey-4 w-14 h-14 flex justify-center items-center hover:filter hover:brightness-95"
                        >
                          <div className="flex space-x-1">
                            <span className="w-1.5 h-1.5 bg-grey-2 rounded-full"></span>
                            <span className="w-1.5 h-1.5 bg-grey-2 rounded-full"></span>
                            <span className="w-1.5 h-1.5 bg-grey-2 rounded-full"></span>
                          </div>
                        </button>
                      </>
                    ) : (
                      <>
                        <CollectBtn
                          onClick={handleCollectClick}
                          disabled={isCollectPending}
                        />
                        <ShareBtn isSmall={true} goodId={goodId} />
                      </>
                    )}
                  </div>
                  {collected_by_names.length > 0 && (
                    <div className="mb-6 text-grey-1">
                      <HeartIcon className="inline w-5 h-5 mr-2 mb-1 text-grey-2" />
                      Collected by{" "}
                      <span>
                        {collected_by_names
                          .slice(0, 2)
                          .map((name, index) => {
                            return (
                              <Link
                                key={index}
                                to={`/${collected_by_link_tags[index]}`}
                                className="text-primary font-medium"
                                onClick={onPopupClose}
                              >
                                {name}
                              </Link>
                            );
                          })
                          .reduce((prev, curr) => [prev, ", ", curr])}
                      </span>
                      {collected_by_names.length > 2 && (
                        <span className="text-primary">
                          {" "}
                          &{" "}
                          <button
                            className="underline"
                            onClick={toggleCollectedBy}
                          >
                            {collected_by_names.length - 2} others
                          </button>
                        </span>
                      )}
                    </div>
                  )}
                  <p className="mb-4 text-grey-1">
                    <GridIcon className="inline w-5 h-5 mr-2 mb-1 text-grey-2" />
                    Similar Goods
                    <span className="text-grey-2 bg-grey-4 rounded-full px-2 py-1.5 text-xs/none ml-2">
                      BETA
                    </span>
                  </p>
                  <Suspense fallback={<div>Loading...</div>}>
                    <GoodsGrid
                      showSkeleton={isLoadingSimilar || !similarGoods}
                      showOwner={false}
                      ref={ref}
                      isLoadingNextPage={
                        isFetchingNextPageSimilar || hasNextPageSimilar
                      }
                      openGoodModal={(goodId) => {
                        openGoodModal(
                          goodId,
                          selectedCategory,
                          userId,
                          "similar_goods"
                        );
                      }}
                      goods={similarGoods}
                    />
                  </Suspense>
                  <div ref={ref} /> {/* Scroll detection element */}
                </div>
                {bottom_addons}
              </motion.div>
            )}
          </AnimatePresence>
        </div>
        <Suspense fallback={<div>Loading...</div>}>
          <CollectedByModal
            key="collectedByModal"
            isOpen={collectedByOpen}
            onClose={toggleCollectedBy}
            collected_by_names={collected_by_names}
            collected_by_link_tags={collected_by_link_tags}
            onClick={onPopupClose}
          />
        </Suspense>
        <Suspense fallback={<div>Loading...</div>}>
          <GoodMoreModal
            goodTitle={goodDetails?.title}
            goodId={goodId}
            key="goodMoreModal"
            isOpen={goodMoreOpen}
            onClose={() => setGoodMoreOpen(false)}
            onArchiveClick={handleArchiveClick}
            onPopupClose={onPopupClose}
            labels={goodDetails?.labels}
          />
        </Suspense>
        <Suspense fallback={<div>Loading...</div>}>
          <AddToCollectionModal
            key="addToCollectionModal"
            isOpen={addToCollectionOpen}
            onClose={() => setAddToCollectionOpen(false)}
            goodId={goodId}
            onPopupClose={onPopupClose}
          />
        </Suspense>
        <BottomModal
          isOpen={isOwnedPopupOpen}
          onClose={() => setOwnedPopupOpen(false)}
          zIndex={21}
        >
          <div className="px-4 mt-4">
            <div className="max-w-[350px]">
              <h2 className="text-xl font-bold mb-12">
                {owned_by_user_ids.includes(userId)
                  ? `Remove "${goodDetails?.title}" from owned?`
                  : `Do you own "${goodDetails?.title}"?`}
              </h2>
            </div>
            <PrimaryButton
              onClick={() => {
                if (owned_by_user_ids.includes(userId))
                  markAsUnownedMutation(goodId);
                else markAsOwnedMutation(goodId);

                setOwnedPopupOpen(false);
              }}
              className="w-full bg-primary rounded-full text-white text-lg mb-4"
            >
              {owned_by_user_ids.includes(userId)
                ? "Remove from owned"
                : "Mark as owned"}
            </PrimaryButton>
            <PrimaryButton
              onClick={() => {
                setOwnedPopupOpen(false);
              }}
              className="w-full bg-grey-4 rounded-full text-black text-lg mb-10"
            >
              Cancel
            </PrimaryButton>
          </div>
        </BottomModal>
      </BottomModal>
    </>
  );
}

export default GoodModal;
