import * as amplitude from "@amplitude/analytics-browser";
import GoodModal from "components/modals/good_modal/GoodModal";
import { createContext, useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const GoodModalContext = createContext();

const GoodModalProvider = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [modals, setModals] = useState([]);

  const openGoodModal = (goodId, category, userId, context) => {
    setModals((currentModals) => [
      ...currentModals,
      { goodId, category, userId, show: false },
    ]);

    setTimeout(() => {
      setModals((currentModals) =>
        currentModals.map((modal) =>
          modal.goodId === goodId ? { ...modal, show: true } : modal
        )
      );
    }, 10);

    navigate(`${location.pathname}?good_id=${goodId}`, {
      replace: true,
    });
    amplitude.track("good_opened", { good_id: goodId, flow: context });
  };

  const closeGoodModal = (goodId) => {
    setModals((currentModals) =>
      currentModals.map((modal) =>
        modal.goodId === goodId ? { ...modal, show: false } : modal
      )
    );

    // Update URL if the closing modal was the last one
    if (modals.length - 1 > 0) {
      const lastModal = modals[modals.length - 2];
      navigate(`${location.pathname}?good_id=${lastModal.goodId}`, {
        replace: true,
      });
    } else {
      const params = new URLSearchParams(location.search);
      params.delete("good_id");
      navigate(`${location.pathname}?${params.toString()}`, {
        replace: true,
      });
    }

    setTimeout(() => {
      setModals((currentModals) => {
        const updatedModals = currentModals.filter(
          (modal) => modal.goodId !== goodId
        );

        return updatedModals;
      });
    }, 300); // Adjust the timeout duration to match your animation duration
  };

  return (
    <GoodModalContext.Provider value={{ openGoodModal, closeGoodModal }}>
      {children}

      {modals.map(({ goodId, category, userId, show }) => (
        <GoodModal
          key={goodId}
          goodId={goodId}
          selectedCategory={category}
          show={show}
          onClose={() => closeGoodModal(goodId)}
          ownerUserId={userId}
        />
      ))}
    </GoodModalContext.Provider>
  );
};

const useGoodModal = () => useContext(GoodModalContext);

export { GoodModalProvider, useGoodModal };
