import * as amplitude from "@amplitude/analytics-browser";
import { useEffect } from "react";
import BottomModal from "../bottom_modal/BottomModal";
import Notifcation from "./Notification";

const NotificationsModal = ({ isOpen, onClose, notifications }) => {
  useEffect(() => {
    if (isOpen) amplitude.track("notifications_opened");
  }, [isOpen]);

  const onNotificationClick = () => {
    amplitude.track("notification_clicked");
  };

  return (
    <BottomModal
      isOpen={isOpen}
      onClose={onClose}
      containerClassName={"max-w-[550px] min-h-[400px]"}
      zIndex={20}
    >
      <div>
        <h2 className="text-black text-xl font-bold ml-4 mb-8">
          {notifications?.filter((n) => !n.is_read).length > 0
            ? `${notifications?.filter((n) => !n.is_read).length} New`
            : ""}{" "}
          Notifications
        </h2>
        <div className="flex flex-col items-center">
          {(!notifications || notifications.length === 0) && (
            <p className="text-lg text-grey-2 max-w-80 text-center m-auto">
              You haven't received any notifications yet. Keep saving your items
              and exploring Goodsend.
            </p>
          )}
          {notifications &&
            notifications.map((notification, i) => (
              <Notifcation
                key={i}
                notification={notification}
                onClick={onNotificationClick}
              />
            ))}
        </div>
      </div>
    </BottomModal>
  );
};

export default NotificationsModal;
