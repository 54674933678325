import { useQuery, useQueryClient } from "@tanstack/react-query";
import { ReactComponent as BellIcon } from "assets/icons/bell.svg";
import { ReactComponent as CogIcon } from "assets/icons/cog.svg";
import { useEffect, useState } from "react";

import * as amplitude from "@amplitude/analytics-browser";
import { useMutation } from "@tanstack/react-query";
import { usePaywall } from "PaywallContext";
import { useUser } from "UserContext";
import { ReactComponent as CircleIcon } from "assets/icons/circle.svg";
import { ReactComponent as RoundedMinus } from "assets/icons/rounded_minus.svg";
import { ReactComponent as RoundedPlus } from "assets/icons/rounded_plus.svg";
import { ReactComponent as SpinnerIcon } from "assets/icons/spinner.svg";
import { ReactComponent as StarIcon } from "assets/icons/star.svg";
import UpdateInfoBanner from "components/UpdateInfoBanner";
import {
  followUser,
  getUserFollowees,
  getUserNotifications,
  readUserNotifications,
  unfollowUser,
} from "services/api";
import PaywallBanner from "./PaywallBanner";
import PrimaryButton from "./PrimaryButton";
import ShareableLink from "./ShareableLink";
import UserAvatar from "./UserAvatar";
import UserInfoSkeleton from "./UserInfoSkeleton";
import CircleModal from "./modals/circle_modal/CircleModal";
import NotificationsModal from "./modals/notifications_modal/NotificationsModal";
import SettingsModal from "./modals/settings_modal/SettingsModal";

const UserInfo = ({
  className,
  user,
  isMyPage = false,
  isOnboarding = false,
}) => {
  const { openPaywall } = usePaywall();
  const { user: currentUser } = useUser();
  const queryClient = useQueryClient();

  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const onNotificationClick = () => {
    setNotificationsOpen(true);
  };
  const onNotificationClose = () => {
    setNotificationsOpen(false);
    let notifications = queryClient.getQueryData([
      "userNotifications",
      user?.id,
    ]);
    markAllAsRead();
    queryClient.setQueryData(
      ["userNotifications", user?.id],
      notifications.map((n) => ({ ...n, is_read: true }))
    );
  };

  const [settingsOpen, setSettingsOpen] = useState(false);
  const toggleSettings = () => {
    setSettingsOpen((prev) => !prev);
    if (!settingsOpen) amplitude.track("settings_opened");
  };

  const [circleOpen, setCircleOpen] = useState(false);
  const toggleCircle = () => {
    setCircleOpen((prev) => !prev);
  };

  const {
    data: notifications,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["userNotifications", user?.id],
    queryFn: () => getUserNotifications(user?.id),
    enabled: !!user?.id && isMyPage,
  });

  const { data: followees } = useQuery({
    queryKey: ["userFollowees", user?.id],
    queryFn: () => getUserFollowees(user?.id),
    enabled: !!user?.id,
  });

  const {
    data: currentUsersFollowees,
    isFetching: isCurrentUsersFolloweesPending,
  } = useQuery({
    queryKey: ["userFollowees", currentUser?.id],
    queryFn: () => getUserFollowees(currentUser?.id),
    enabled: !!currentUser?.id,
  });

  const { mutate: markAllAsRead } = useMutation({
    mutationFn: readUserNotifications,
  });

  const { mutate: addToCircle, isPending: isAddToCirclePending } = useMutation({
    mutationFn: () => followUser(user.id),
    onSettled: () => {
      queryClient.invalidateQueries(["userFollowees", currentUser?.id]);
      queryClient.invalidateQueries(["userFollowees", user?.id]);
      amplitude.track("add_to_circle_clicked");
    },
  });

  const { mutate: removeFromCircle, isPending: isRemoveFromCirclePending } =
    useMutation({
      mutationFn: () => unfollowUser(user.id),
      onSettled: () => {
        queryClient.invalidateQueries(["userFollowees", currentUser?.id]);
        queryClient.invalidateQueries(["userFollowees", user?.id]);
        amplitude.track("remove_from_circle_clicked");
      },
    });

  useEffect(() => {
    if (
      isMyPage &&
      currentUser &&
      currentUser.privacy !== 0 &&
      !currentUser.is_premium
    ) {
      openPaywall("keep_private", "privacy_migration_banner");
    }
  }, [currentUser, isMyPage, openPaywall]);

  // console.log("isAddToCirclePending", isAddToCirclePending);
  // console.log("isRemoveFromCirclePending", isRemoveFromCirclePending);
  // console.log("notifications", notifications);
  if (!user) return <UserInfoSkeleton />;
  // console.log("!!user && isMyPage", !!user && isMyPage);
  return (
    <div className={`relative ${className} `}>
      {isMyPage && (
        <>
          <CogIcon
            onClick={toggleSettings}
            className="absolute top-0 right-2 w-7 h-7 cursor-pointer hover:filter hover:brightness-95 text-grey-2 active:scale-[0.9775]"
          />
          <SettingsModal isOpen={settingsOpen} onClose={toggleSettings} />
          <div className="absolute top-0 left-2">
            <BellIcon
              onClick={onNotificationClick}
              className="w-7 h-7 cursor-pointer hover:filter hover:brightness-95 active:scale-[0.9775]"
            />
            {notifications?.filter((n) => !n.is_read).length > 0 && (
              <div className="flex items-center justify-center absolute -top-2 -right-2 w-6 h-6 bg-primary rounded-full text-white text-xs">
                {notifications?.filter((n) => !n.is_read).length}
              </div>
            )}
          </div>
          <NotificationsModal
            isOpen={notificationsOpen}
            onClose={onNotificationClose}
            notifications={notifications}
          />
          {(user?.photo_url === "" || /^\d+$/.test(user?.link_tag)) && (
            <UpdateInfoBanner />
          )}
        </>
      )}
      <div className="flex justify-center items-center flex-col text-center  after:content-[''] after:absolute after:z-[-1] after:bottom-[-40px] after:block after:w-[760px] after:h-[760px] after:bg-grey-4 after:rounded-full">
        <UserAvatar
          size="large"
          image_url={user?.photo_url}
          username={user?.name}
          className="mb-4"
        />
        <div className="text-2xl/8 font-black mb-1">{user?.name}</div>
        <ShareableLink to={`https://goodsend.it/${user.link_tag}`}>
          {`goodsend.it/${user.link_tag}`}
        </ShareableLink>
        {isOnboarding ? (
          <div className="mb-10"></div>
        ) : (
          <div
            onClick={() => {
              setCircleOpen(true);
              amplitude.track("circle_clicked");
            }}
            className="flex items-center border-grey-3 border-[1px] bg-white rounded-full p-1 mt-3 mb-4 cursor-pointer hover:border-grey-3-hover active:scale-[0.9875] transition-all"
          >
            <CircleIcon
              className={`w-4 h-4 ${
                followees?.length > 0 ? "ml-2 mr-1" : "ml-2 mr-1"
              }`}
            />
            <div className="flex">
              {followees?.length > 0 ? (
                <>
                  {followees?.slice(0, 2).map((followee, i) => (
                    <UserAvatar
                      key={i}
                      size="small"
                      image_url={followee.photo_url}
                      username={followee.name}
                      className={`border-[1px] border-white ${
                        i === 0 ? "" : "-ml-3"
                      }`}
                    />
                  ))}
                  {followees.length - 2 > 0 && (
                    <div
                      className={
                        "flex w-10 h-10 justify-center items-center rounded-full bg-grey-3 text-primary font-regular font-bold border-[1px] border-white -ml-3"
                      }
                    >
                      +{followees.length - 2 > 99 ? "99" : followees.length - 2}
                    </div>
                  )}
                </>
              ) : (
                <p className="text-sm/none text-grey-2 py-[13px] mr-3">
                  Circle Empty
                </p>
              )}
            </div>
          </div>
        )}
        {isMyPage && !currentUser?.is_premium && (
          <>
            <PrimaryButton
              onClick={() => openPaywall("products", "profile_button")}
              className="absolute bottom-[-52px] p-4 text-sm/none"
            >
              <StarIcon className="w-4 h-4 mr-1" />
              Join Goodsend Premium
            </PrimaryButton>
            <PaywallBanner />
          </>
        )}
        {}
        {!isMyPage && !isOnboarding && (
          <>
            {currentUsersFollowees?.some(
              (follower) => follower.id === user?.id
            ) ? (
              <>
                <PrimaryButton
                  onClick={removeFromCircle}
                  className="absolute bottom-[-52px] p-4 text-sm/none bg-white border-grey-3 border-[1px] text-primary"
                >
                  {isRemoveFromCirclePending ||
                  isCurrentUsersFolloweesPending ? (
                    <SpinnerIcon className="w-4 h-4 animate-spin mr-1" />
                  ) : (
                    <RoundedMinus className="w-4 h-4 mr-1" />
                  )}{" "}
                  Remove from Circle
                </PrimaryButton>
              </>
            ) : (
              <PrimaryButton
                onClick={addToCircle}
                className="absolute bottom-[-52px] p-4 text-sm/none"
              >
                {isAddToCirclePending || isCurrentUsersFolloweesPending ? (
                  <SpinnerIcon className="w-4 h-4 text-white animate-spin mr-1" />
                ) : (
                  <RoundedPlus className="text-white w-4 h-4 mr-1" />
                )}
                Add to Circle
              </PrimaryButton>
            )}
          </>
        )}
        <CircleModal
          isOpen={circleOpen}
          onClose={() => setCircleOpen(false)}
          username={user.name}
          followees={followees}
        />
      </div>
    </div>
  );
};
export default UserInfo;
