import * as amplitude from "@amplitude/analytics-browser";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useToast } from "ToastContext";
import PrimaryButton from "components/PrimaryButton";
import { categories } from "config";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ReactComponent as CameraIcon } from "../assets/icons/camera.svg";
import { ReactComponent as LeftArrow } from "../assets/icons/left_arrow.svg";
import { ReactComponent as LinkIcon } from "../assets/icons/link_square.svg";
import { getGoodDetails, updateGoodDetails } from "../services/api";

function GoodEdit({ goodId }) {
  const { addToast } = useToast();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    amplitude.track(`goodedit_opened`);
  }, []);

  // const [selectedCategories, setSelectedCategories] = useState([]);
  const [imagePreview, setImagePreview] = useState(null); // State to hold the image preview URL

  const { mutate, isPending, isSuccess } = useMutation({
    mutationFn: ({ goodId, formData }) => updateGoodDetails(goodId, formData),
    onSettled: () => {
      queryClient.invalidateQueries(["goodDetails", goodId]);
      addToast("Changes Saved");
    },
  });

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append("title", data.title);
    formData.append("url", data.url);
    formData.append("price_usd", data.price_usd || 0);

    if (data.categories)
      formData.append(
        "categories",
        data.categories.filter((c) => c !== "")
      );
    if (data.image) {
      if (data.image instanceof File) {
        formData.append("image", data.image);
      } else formData.append("image", imagePreview);
    }
    mutate({ goodId, formData });
  };

  const onFileChange = (file) => {
    if (file) {
      setImagePreview(URL.createObjectURL(file)); // Create and set the image preview URL
    }
    setValue("image", file);
  };

  const toggleCategory = (category) => {
    if (selectedCategories.includes(category)) {
      setValue(
        "categories",
        selectedCategories.filter((c) => c !== category)
      );
    } else {
      setValue("categories", [...selectedCategories, category]);
    }
    console.log("selectedCategories", selectedCategories);
  };

  useEffect(() => {
    // Clean up the image preview URL to avoid memory leaks
    return () => {
      if (imagePreview) {
        URL.revokeObjectURL(imagePreview);
      }
    };
  }, [imagePreview]);

  const { data, isLoading, isError } = useQuery({
    queryKey: ["goodDetails", goodId],
    queryFn: () => getGoodDetails(goodId),
    enabled: !!goodId,
  });

  useEffect(() => {
    if (data) {
      setValue("url", data.url);
      setValue("title", data.title);
      setValue("categories", data.category_ids.split(", "));
      setValue("price_usd", data.price_usd);
      if (data.image_url)
        setImagePreview(
          `https://goodsend.fra1.cdn.digitaloceanspaces.com/main-space/images/${data.image_url}`
        );
    }
  }, [data, setValue]);

  const urlValue = watch("url");
  const selectedCategories = watch("categories");

  // Drag and drop handlers
  const handleDragOver = useCallback((event) => {
    event.preventDefault(); // Prevent default behavior to enable drop
  }, []);

  const handleDrop = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    const files = event.dataTransfer.files;
    if (files && files[0]) {
      onFileChange(files[0]); // Handle the first file
    }
  }, []);

  if (!isLoading && !goodId) {
    return <div>Good not found</div>;
  }
  return (
    <div
      className="p-3 pb-32 md:pt-4"
      style={{ paddingTop: "env(safe-area-inset-top)" }}
    >
      <div className="flex justify-between align-center mb-8 p-2">
        <LeftArrow
          onClick={() => navigate(-1)}
          className="absolute text-black flex-grow-0"
        />
        <h3 className="text-lg font-bold flex-grow text-center">
          Update Goods Details
        </h3>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="relative">
          <input
            id="url"
            type="url"
            className="bg-grey-4 text-lg p-4 rounded-full w-full mb-4 pe-10 focus:outline focus:outline-1 outline-black"
            {...register("url", { required: true, pattern: /https?:\/\/.+/ })}
          />
          <a
            className="absolute top-[18px] right-4"
            href={urlValue || "#"}
            target="_blank"
            rel="noreferrer"
          >
            <LinkIcon />
          </a>
          {errors.url && <p>Url is required and must be a valid url.</p>}
        </div>

        <div className="mb-6">
          <input
            id="title"
            type="title"
            className="bg-grey-4 text-lg p-4 rounded-full w-full focus:outline focus:outline-1 outline-black"
            {...register("title", { required: true, minLength: 3 })}
          />
          {errors.title && (
            <p>Title is required and must be at least 3 characters long.</p>
          )}
        </div>

        {data?.image_url && (
          <div>
            <p className="mb-4">Categories</p>
            <div className="mb-4">
              {categories.map((category, index) => (
                <button
                  key={index}
                  type="button"
                  className={`text-sm/none p-2 rounded-full mr-2 mb-2 ${
                    selectedCategories?.includes(category.id)
                      ? "bg-primary text-white"
                      : "bg-grey-4 text-primary"
                  }`}
                  onClick={() => toggleCategory(category.id)}
                >
                  {category.name}
                </button>
              ))}
            </div>
          </div>
        )}

        <div className="mb-6 relative after:absolute after:left-4 after:top-14 after:content-['$'] after:mr-2 after:text-lg">
          <p className="mb-4">Price</p>
          <input
            id="title"
            type="title"
            className="bg-grey-4 text-lg p-4 rounded-full w-full focus:outline focus:outline-1 outline-black ps-8"
            {...register("price_usd", {
              valueAsNumber: true,
              min: 0,
            })}
          />
          {errors.price_usd && (
            <p>Price must be a number and greater than 0.</p>
          )}
        </div>

        <div className="mb-8">
          <p className="mb-6">Primary Image</p>
          <div
            className={`bg-grey-4 w-full height-100 rounded-3xl
            flex flex-col items-center ${
              imagePreview ? "justify-end" : "justify-center"
            } mb-4 aspect-square
            bg-cover bg-center bg-no-repeat`}
            style={{ backgroundImage: `url(${imagePreview})` }}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
          >
            {imagePreview ? (
              <div className="mb-8">
                <label
                  htmlFor="image"
                  className="bg-primary p-2 rounded-full text-white mr-8"
                >
                  Change Image
                </label>
                <button
                  className="text-primary font-bold underline"
                  type="button"
                  onClick={() => setImagePreview(null)}
                >
                  Remove Image
                </button>
              </div>
            ) : (
              <>
                <CameraIcon className="mb-4" />
                <label
                  htmlFor="image"
                  className="bg-primary p-2 rounded-full text-white"
                >
                  Upload Image
                </label>
              </>
            )}
            <input
              id="image"
              type="file"
              {...register("image")}
              onChange={(e) => onFileChange(e.target.files[0])}
              className="hidden"
            />
          </div>
        </div>

        <PrimaryButton
          className="w-[95vw] max-w-[500px] fixed bottom-10 left-1/2 -translate-x-1/2"
          type="submit"
        >
          {isPending ? "Saving..." : "Save Changes"}
        </PrimaryButton>
      </form>
    </div>
  );
}

export default GoodEdit;
