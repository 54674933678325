import * as amplitude from "@amplitude/analytics-browser";
import { useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";

const categories = [
  "All",
  "Clothing",
  "Shoes",
  "Electronics",
  "Jewellery",
  "Bags",
  "Watches",
  "Accessories",
  "Homeware",
  "Art&Craft",
  "Other",
];

const Categories = ({
  selectedCategory,
  setSelectedCategory,
  className,
  expand = false,
}) => {
  const [showAll, setShowAll] = useState(expand);
  const largeScreenSize = 768; // Define the breakpoint for large screens
  const initialDisplayCount = expand ? categories.length : 5; // Initial number of categories to display
  const [displayCount, setDisplayCount] = useState(initialDisplayCount);

  useEffect(() => {
    // Function to update the display count based on screen width
    const updateDisplayCount = () => {
      if (window.innerWidth >= largeScreenSize) {
        setDisplayCount(categories.length); // Show all categories on large screens
      } else {
        setDisplayCount(initialDisplayCount); // Show limited categories on small screens
      }
    };

    updateDisplayCount(); // Call on component mount
    window.addEventListener("resize", updateDisplayCount); // Add resize listener

    // Cleanup
    return () => window.removeEventListener("resize", updateDisplayCount);
  }, []);

  const toggleShowAll = () => {
    setShowAll((prevShowAll) => !prevShowAll);
  };

  const onCategoryClick = (index) => {
    setSelectedCategory(index);
    amplitude.track("filters_applied", { category: categories[index] });
  };

  return (
    <div
      className={twMerge(
        "flex justify-center items-center mx-auto text-center flex-wrap max-w-full",
        className
      )}
    >
      {categories
        .slice(0, showAll ? categories.length : displayCount)
        .map((category, index) => (
          <button
            key={category}
            className={`inline-block px-4 py-2 mb-3 mr-2 text-sm/tight bg-grey-4 rounded-full hover:filter hover:brightness-95 active:scale-[0.9875] ${
              selectedCategory === index
                ? "text-primary font-bold"
                : "text-grey-2"
            }`}
            onClick={() => onCategoryClick(index)}
          >
            {category}
          </button>
        ))}
      {displayCount < categories.length && !expand && (
        <button
          className="inline-block px-4 py-2 mb-3 mr-2 text-sm text-primary underline underline-offset-2 rounded-full hover:filter hover:brightness-95"
          onClick={toggleShowAll}
        >
          {showAll ? "Show less" : "Show all"}
        </button>
      )}
    </div>
  );
};

export default Categories;
