import React from "react";
import Good from "./Good";
import GoodSkeleton from "./GoodSkeleton";

const GoodsGrid = React.memo(
  React.forwardRef(
    (
      {
        showSkeleton,
        showOwner,
        openGoodModal,
        openCollectionModal,
        goods,
        isLoadingNextPage = null,
        addons = null,
        prepend = null,
      },
      ref = null
    ) => {
      // console.log("GoodsGrid was rendered at", new Date().toLocaleTimeString());
      // // check if props changed
      // console.log("showSkeleton", showSkeleton);
      // console.log("showOwner", showOwner);
      // console.log("goods", goods);
      // console.log("openGoodModal", openGoodModal);
      // console.log("openCollectionModal", openCollectionModal);

      return (
        <>
          {showSkeleton ? (
            <div
              key="skeleton"
              className="grid gap-3.5 w-full grid-cols-[repeat(auto-fill,_minmax(160px,_1fr))] md:grid-cols-[repeat(auto-fill,_minmax(200px,_1fr))]"
            >
              <GoodSkeleton goods={12} showOwner={showOwner} />
            </div>
          ) : (
            <div
              key="goods"
              className="grid gap-3.5 w-full grid-cols-[repeat(auto-fill,_minmax(160px,_1fr))] md:grid-cols-[repeat(auto-fill,_minmax(200px,_1fr))]"
            >
              {prepend}
              {"pages" in goods
                ? goods.pages.map((page, i) => (
                    <React.Fragment key={i}>
                      {page.goods.map((good) => (
                        <Good
                          data={good}
                          key={good.id}
                          onClick={() =>
                            good.is_collection
                              ? openCollectionModal(good.id)
                              : openGoodModal(good.id)
                          }
                        >
                          {addons && addons(good)}
                        </Good>
                      ))}
                    </React.Fragment>
                  ))
                : goods.map((good) => (
                    <Good
                      data={good}
                      key={good.id}
                      onClick={() =>
                        good.is_collection
                          ? openCollectionModal(good.id)
                          : openGoodModal(good.id)
                      }
                    >
                      {addons && addons(good)}
                    </Good>
                  ))}
              {isLoadingNextPage && ref && (
                <GoodSkeleton ref={ref} goods={1} showOwner={showOwner} />
              )}
            </div>
          )}
        </>
      );
    }
  )
);

export default GoodsGrid;
